// extracted by mini-css-extract-plugin
export var barContainer = "BlogPostCategoryNavbar__barContainer__cBmZk";
export var barWrapper = "BlogPostCategoryNavbar__barWrapper__kibst";
export var column = "BlogPostCategoryNavbar__column__dQden";
export var flex = "BlogPostCategoryNavbar__flex__nbGFN";
export var flexColumn = "BlogPostCategoryNavbar__flexColumn__uHj1M";
export var gap1 = "BlogPostCategoryNavbar__gap1__SiTHt";
export var gap2 = "BlogPostCategoryNavbar__gap2__XYKhf";
export var gap3 = "BlogPostCategoryNavbar__gap3__eoS_F";
export var gap4 = "BlogPostCategoryNavbar__gap4__WLoXJ";
export var gap5 = "BlogPostCategoryNavbar__gap5__psqlh";
export var linkContainer = "BlogPostCategoryNavbar__linkContainer__sojBn";
export var linkItem = "BlogPostCategoryNavbar__linkItem__Fakut";
export var row = "BlogPostCategoryNavbar__row__Dt4q7";
export var underlinedLink = "BlogPostCategoryNavbar__underlinedLink__fpg7s";